//headings
.page__heading {
  font-family: $headingFont;
  color: $secondColor;
  &--inverted {
    color: $forthColor;
  }
}
@for $i from 1 through 6 {
  h#{$i} {
    font-size: 10px + 11px * (4 - $i);
    text-align: center;
    font-family: $primaryFont;
    margin: 0.5em 0;
  }
}

@mixin boxShadow {
  box-shadow: 0 6px 10px $dropShadow-black;
}

/*=======================[Grid]=======================*/

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  ::after {
    content: "";
    clear: both;
    display: table;
  }
}
[class*="col-"] {
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  flex-shrink: 1;
  align-items: stretch;
  min-height: 1px;
  padding: $spacingPrimary;
}
.column {
  @extend [class*="col-"];
  margin: $spacingPrimary;
  padding: 0;
}

@mixin makeGrid ($cols-in-grid: 12, $prefix: "col-") {
  @for $i from 1 through $cols-in-grid {
    .#{$prefix}#{$i} {
      width: $i / $cols-in-grid * 100%;
    }
  }
}

@media only screen {
  /*for all*/
  @include makeGrid($prefix:"col-");

  @media (min-width: 0) and (max-width: $mobile) {
    /*for mobile*/
    @include makeGrid($prefix:"col-m-");
  }
  @media (min-width: $mobile) and (max-width: $tablet) {
    /*for tablet*/
    @include makeGrid($prefix:"col-t-");
  }
  @media (min-width: $tablet) {
    /*for desktop*/
    @include makeGrid($prefix:"col-d-");
  }
}