//Start of project filter
.projects-filter {
  padding: 125px 0 35px;
  background: $secondColor;
  box-shadow: 0 -15px 15px $dropShadow-black;
  color: $white;
  &__form {
    margin: 0 auto;
  }
  &__item {
    margin: 10px 0;
  }
  &__list {
    margin: $spacingSecondary 0;
  }
}
.filter-item{
  &:hover {
    text-decoration: underline;
  }
  &__toggle {
    display: none;
  }
  &--active {
    font-weight: bold;
  }
}
//end of project filter
//start of projects
//Start of project list
.projects__list {
  padding: 100px 15px 25px;
}
//End of project list

//start of projects display
.projects__display {
  margin: 50px 0;
  padding: 25px 0;
  @include boxShadow;
  &--odd {
    @extend .projects__display;
    background-color: $primaryColor;
    color: white;
  }
  &--even {
    @extend .projects__display;
    background-color: $card-bg;
    color: black;
  }

  //start of project info
  .projects__heading {
    &--odd {
      color: $forthColor;
    }
    &--even {
      color: $primaryColor;
    }
  }
  .projects-display__tags {
    text-align: center;
    margin-bottom: 20px;
  }
  .projects-tags__item {
    display: inline-block;
    padding: 5px 10px;
    margin: 0 10px;
    border-bottom: 1px solid;
    font-style: oblique;
    &--odd {
      @extend .projects-tags__item;
    }
    &--even {
      @extend .projects-tags__item;
    }
  }
  .projects-info {
    &__name {
      margin-bottom: 30px;
    }
  }
  //end of project info
  //start if project showcase
  .projects-display__showcase {
    padding: 40px 30px;
  }
  .project-showcase__img {
    max-width: 100%;
    margin: 0 auto;
    display: block;
    &:hover {
      opacity: 0.8;
      transition-duration: 0.15s;
    }
  }
  //end of project showcase
}
.projects-display__btn {
  margin-top: 25px;
}
//End of project display
//end of projects

@media only screen {
  //for tablet
  @media (min-width: $mobile) and (max-width: $tablet) {
    //start of project filter
    .projects-filter {
      &__form {
        width: 100%;
        text-align: center;
        display: flex;
        flex-direction: row;
        margin: 0 auto;
      }
    }
    //end of project filter
  }

  //for tablet and up
  @media (min-width: $mobile) {
    .projects__list {
      padding: 150px 50px 25px;
    }
    .projects__display {
      padding: 50px;
    }
    .projects-display__info {
      padding: 40px 30px;
    }
  }

  //for desktop
  @media (min-width: $tablet){

    //start of projects filter
    .projects-filter {
      min-height: 100vh;
      padding: 200px $spacingPrimary 25px;
      &__form {
        position: sticky;
        top: 30%;
        display: block;
        flex-direction: unset;
      }
    }
    //End of projects filter
    //Start of project item

    //End of project item
  }
}