
*, *::before, *::after {
  box-sizing: border-box;
}

.html {
  height: 100%;
  font-family: sans-serif;
}
.body {
  font-family: $primaryFont;
}

//Start of Global Presets
.clearfix::after {
  content: '';
  clear: both;
  display: table;
}
.hidden {
  display: none !important;
}
.link {
  &:hover {
    text-decoration: underline;
  }
}

//Start of Navigation
.nav {
  font-family: sans-serif;
  font-size: 20px;
  z-index: 99;
  width: 100%;
  position: absolute;
  top: 0;
  @extend .clearfix;
  &--mobile {
    @extend .nav;
    background-color: $blackTransparent;
    height: 275px;
    @include boxShadow;
  }
  &__action {
    background-color: rgba(0,0,0,0.3);
  }
  &__logo {
    float: left;
    margin: 5px;
  }
  &__links {
    margin-top: 80px;
    position: absolute;
    width: 100%;
    text-align: center;
  }
  &__link {
    font-size: 1.25em;
    color: $white;
    text-decoration: none;
    display: none;
    padding: $spacingPrimary;
    &:hover {
      transform: scale(1.1);
      transition-duration: 0.15s;
      background-color: rgba(255,255,255,0.3);
    }
    &--mobile {
       @extend .nav__link;
      display: block;
    }
    &--inverted {
      @extend .nav__link;
    }
    &--active {
      text-decoration: underline $thirdColor;
    }
  }
  &__menuToggle {
    @extend  .nav__link;
    margin: 8px;
    float: right;
    border: none;
    display: block;
  }
}
.nav-logo__item {
  @extend .link;
  padding: 10px;
  width: 70px;
}

//End of Navigation

//Start of container types
.page {
  min-height: 100vh;
  background-color: $background;
  > *:last-child {
    margin-bottom: 50px;
  }
  &__h1 {
    margin-top: 50px;
    text-align: center;
  }
}
.container {
  margin: 75px 0;
}
.flex {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  flex-grow: 0;
}

.section {
  margin: $spacingPrimary 0;
}
//End of container types

//Start of element types
.bgImg {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.btn {
  padding: $spacingSecondary $spacingPrimary;
  border-radius: 5px;
  color: white;
  border: none;
  background-color: $secondColor;
  box-shadow: 0 2px 6px $dropShadow-black;
  transition-duration: 0.2s;
  &:hover {
    background-color: $primaryColor;
  }
  &:active {
    box-shadow: none;
  }
  &--inverted {
    @extend .btn;
    background-color: $thirdColor;
    &:hover {
      background-color: $forthColor;
    }
  }
}
//Start of form
.form {
  padding: $spacingPrimary;
  &__group {
    padding: $spacingPrimary;
  }
  &__label {
    color: $primaryColor;
  }
  &__item {
    font-family: $secondFont;
    margin: 5px 0;
    padding: $spacingSecondary;
    border-radius: 5px;
    width: 100%;
    border: 2px solid black;
    &:focus {
      border: 2px solid $thirdColor;
    }
  }
  &__textArea {
    resize: vertical;
    height: 150px;
  }
  &__error {
    color: red;
  }
  &__submit {
    display: block;
    width: 120px;
    margin: 0 $spacingPrimary 0 auto;
  }
}
//End of form

//Start of list
.list {
  padding: $spacingPrimary 0;
  &__item {
    padding: $spacingSecondary
  }
}
//End of list

//Start of Paragraph
.paragraph {
  max-width: 1100px;
  margin: 5px 0;
  padding: 10px;
}
//End of Paragraph

//Start of Footer
.footer {
  @extend .clearfix;
  background: #333333;
  color: white;
  width: 100%;
  padding: 20px;
  &__link {
    text-align: center;
    display: inline-block;
    padding: $spacingPrimary;
    &:hover {
      text-decoration: underline;
    }
  }
}
//Start of footer links
.footer-links {
  &__link {
    @extend .footer__link;
  }
}
//end of footer links

//start of footer logo
.footer__logo {

}
.footer-logo {
  margin: 0 auto;
  max-width: 150px;
  &__logo {
    display: block;
    padding: 15px;
    width: 100%;
  }
}

//end of footer logo

//Start of footer tools
.footer-tools {
  &__link {
    @extend .footer__link;
  }
}
//End of footer tools
//End of Footer
//End of element types
//End of Global Presets

@media only screen {
  //for tablets and up
  @media (min-width: $mobile) {
    //start of nav
    .nav {
      padding: 0 $marginSide;
      &__links {
        float: right;
        text-align: left;
        width: unset;
        position: unset;
        margin-top: unset;
      }
      &__link {
        display: inline-block;
        &:hover {
          background-color: unset;
        }
      }
      &__menuToggle {
        display: none;
      }
    }
    //end of nav

    //start of footer
    .footer {
      &__links {
        order: 1;
      }
      &__logo {
        order: 2;
      }
      &__tools {
        order: 3;
      }
    }
    //end of footer
  }
  //for desktop and up
  @media (min-width: $tablet) {
    .nav__link {
      &--inverted {
        color: $primaryColor
      }
    }
  }
}