//Start of Hero
.home__hero {
  position: relative;
}

//Start of Greet
.hero__greet {
  @extend .flex;
  @extend .bgImg;
  background-position: bottom;
  align-content: center;
  justify-content: center;
  min-height: 80vh;
  width: 100%;
  background-image: url("http://www.zettrex.no/media/images/hero.jpg");
  @include boxShadow;
}
.hero-greet{
  display: flex;
  align-content: center;
  justify-content: center;
  background: radial-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.4), rgba(0,0,0,0.0), rgba(0,0,0,0.0));
  width: 100%;
  height: 100%;
  &__h1 {
    margin: 100px auto 225px;
    color: $white;
    display: block;
    text-align: center;
  }
  &__main {
    font-size: 30px;
    margin-bottom: 10px;
  }
  &__sub {
    font-size: 17px;
    margin-bottom: 30px;
  }
  &__welcome {
    font-size: 25px;
  }
}
.hero-h1 {
  &__part {
    display: block;
  }
}
//End of Greet

//Start of InfoPlates
.info-plates {
  width: 80%;
  margin: -200px auto 0;
  justify-content: center;
  &__card {
    color: $black;
    width: 310px;
    padding: 30px;
    text-align: center;
    background-color: $white;
    @include boxShadow;
  }
}
.card {
  &__section {
    margin: 10px 0;
    border-top: 3px solid $thirdColor;
  }
  &__name {
    color: $primaryColor
  }
}
.card-section {
  &__heading {
    margin: 10px 0;
  }
}
//End of InfoPlates
//End of Hero

//start of Projects List
.home__projects {
  padding: 15px;
  text-align: center;
}
//End of Projects List
//Start Project List
.home-project__list {
  margin: $marginSections 0;
}

//Start of Display item
.home-project__display {
  //flex-basis: 500px;
  margin: 0;
  padding: $spacingPrimary;
  min-width: 200px;
}
.home-display {
  position: relative;
  margin-bottom: 50px;
  &--active {
    transform: scale(1.02);
    transition-duration: 0.15s;
  }
  &__click {
    font-style: italic;
    left: 50%;
    position: absolute;
    top: 20px;
    transform: translate(-50%);
    &--hidden {
      display: none;
    }
  }
  &__name {
    margin: 20px 0 10px;
  }
  &__img{
    max-width: 100%;
    display: block;
    border-bottom: 1px solid black;
    @include boxShadow;
    &--faded {
      transition-duration: 0.15s;
      opacity: 0.25;
    }
  }
}

//Start of project tags'
.home-display__tags {
  text-align: center;
}
.home-tags {
  &__item {
    display: inline-block;
    margin: 5px;
    padding: 2px 10px 4px;
    font-style: oblique;
    color: $secondColor;
  }
}
//End of project tags

//End of Display item\
//End Project List

@media only screen {
  @media (max-width: 430px) {
    .home-projects__display {
      width: 100%;
    }
  }
  @media (min-width: $mobile) {
    .hero {
      margin: 0 0 $marginSections;
    }
    .hero-greet {
      &__main {
        font-size: 55px;
      }
      &__sub {
        font-size: 30px;
      }
      &__welcome {
        font-size: 40px;
      }
    }

    .home__projects {
      padding: 100px 75px 50px;
    }
  }

  @media (min-width: $tablet) and (hover: hover) {
    //start of project display
    .home-projects__display {
      flex-basis: 500px;
    }
    .home-display {
      @include boxShadow;
      &__img {
        box-shadow: none;
      }
      &__name {
        font-size: 30px;
        padding: 20px;
        margin: 0;
        text-align: center;
        position: absolute;
        transform: translate(-50%, -50%);
        left: 50%;
        top: 50%;
        &--hidden {
          display: none;
        }
      }
    }

    //start of project tags
    .home-tags {
      position: absolute;
      bottom: 0;
      padding: 25px;
      &--hidden {
        display: none;
      }
      &__item {
        background-color: #174b53;
        color: $white;
        border-radius: 5px;
        padding: 5px 10px;
      }
    }
    //end of project tags
    //end of project display
  }
  @media (min-width: $desktop) {
    .projects-list__display {
      min-width: 700px;
    }
  }
}