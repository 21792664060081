//start of about content
.about__content {
  background-color: $secondColor;
  color: white;
  padding: 100px 50px;
  @include boxShadow;
}
.about-content {
  &__heading {
    color: $forthColor;
  }
  &__img {
    max-width: 350px;
    height: 350px;
    display: block;
    margin: 50px auto 75px;
  }
  &__body {
    max-width: 800px;
    margin: 0 auto;
  }
}

//start of about who am I
.about-whoAmI {
  &__heading {
    text-align: left;
  }
  &__paragraph {
    margin: $spacingPrimary 0;
    font-size: 17px;
  }
}


//end of about who am I

//start of about education
.about-education__heading {
  text-align: left;
}
.about-education__content {
  margin: $spacingPrimary 0;
}
.education-content__type {
  font-weight: bold;
  font-size: 17px;
}

//end of about education

//start of about skills
.about-skills__heading {
  text-align: left;
}
.about-skills__chart {
  display: block;
  width: 100%;
}
.about-chart__group {
  margin: 5px 0 ;
}
.about-chart__bar {
  margin: 2px 0;
  background-color: white;
  border-radius: 5px;
  height: 15px;
  display: block;
}
@for $i from 1 through 100 { //sets with on bar depending on data-percentage
  .about-chart__bar[data-percentage="#{$i}"]{
    width: ($i * 1%);
  }
}
//end of about skills
//end of about content